<template>
  <div class="container">
    <br>
    <!-- blogpost start -->
    <article class="clearfix blogpost full">
      <div class="blogpost-body">
        <div class="side">
          <div class="post-info">
            <span class="day">{{ item.issueDate | dateDay }}</span>
            <span class="month">{{ item.issueDate | dateMonth(locale) }} {{ item.issueDate | dateYear }}</span>
          </div>
          <!--            <div id="affix"><span class="share">Share This</span><div id="share"></div></div>-->
        </div>
        <div v-if="locale === 'en'" class="blogpost-content" v-html="item.contentEn" />
        <div v-else class="blogpost-content" v-html="item.content" />
      </div>
      <footer class="clearfix">
        <ul class="links pull-right">
          <li><i class="fa fa-arrow-left pr-5" /> <router-link :to="{ path: '/news/news-list?type=' + type }">{{ $t('body.back') }}</router-link> </li>
        </ul>
      </footer>
    </article>
    <!-- blogpost end -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getNewsDetail } from '@/api/csp'
export default {
  name: 'NewsDetail',
  data() {
    return {
      id: '',
      type: '',
      item: ''
    }
  },
  computed: {
    ...mapGetters([
      'locale',
      'name',
      'sidebar',
      'avatar',
      'device'
    ])
  },
  mounted() {
    this.id = this.$route.query.id
    this.type = this.$route.query.type
    this.loadData()
  },
  methods: {
    loadData() {
      getNewsDetail(this.id).then(resp => {
        if (resp.status) {
          this.item = resp.data
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
